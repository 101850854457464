regular = 'lato-regular', sans-serif
light   = 'lato-light', sans-serif
bold    = 'lato-bold', sans-serif
medium  = 'lato-medium', sans-serif

h1
h2
h3
h4
h5
h6
  font-family bold
font-size = .8

mw1440        = "only screen and (max-width : 1440px)"
mw1280        = "only screen and (max-width : 1280px)"
tablet        = "only screen and (max-width : 800px)"
tabletAlbum = "only screen and (max-height : 750px)"

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.box-sizing
  -webkit-box-sizing border-box
  -moz-box-sizing border-box
  box-sizing border-box

transition(n)
  -webkit-transition n
  -moz-transition n
  -ms-transition n
  -o-transition n
  transition n
transform(n)
  -webkit-transform n
  -moz-transform n
  -ms-transform n
  -o-transform n
  transform n

.abs
  position absolute
  left 0
  top 0
html
body
  width 100%
  height 100%
  font-family regular
  -webkit-font-smoothing antialiased
  position relative
  overflow hidden
  background white
  // @media mw767
  //  overflow-y visible

b
  font-family bold

.wrapper
  max-width 100%
  min-width 768px
  height 100%
  // @media mw767
  position relative

.popup
  @extend .abs
  display none
  width 100%
  height 100%
  background rgba(38,47,52, .98)
  z-index 999
  &.show
    display block
  img
    @extend .abs
    right 0
    bottom 0
    margin auto
    max-width 80%
    min-height 20%
    max-height 80%
  .arrow
    @extend .abs
    background url(../images/ic_arrow.png) center no-repeat
    cursor pointer
    bottom 0
    left auto
    right 0
    width: 5%
    height 100%
    &.left
      transform(rotate(180deg))
      right auto
      left 0
  .close
    cursor: pointer
    position absolute
    top:  30px;
    right: 30px;
    width 30px
    height 30px
    opacity: .4
    background url(../images/close.png) center no-repeat


.statusMessage
  color #000
  background #273136
  display none
  margin auto
  width 340px
  padding: 0 20px;
  height 190px
  position fixed
  top -120px
  bottom 0
  left 0
  right 0
  z-index 999999
  > div
    display table
    width 100%
    height 100%
  p
    text-transform uppercase
    width 100%
    display: table-cell;
    vertical-align: middle;
    text-align center
    font-size 18px
    color #fff
    line-height 24px
    font-weight 400
#successMessage p
  width 230px
  height 90px
  font-weight 400
  span
    margin-top 10px
    text-transform none
