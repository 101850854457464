.left-block
	transition(all .8s cubic-bezier(0.215, 0.870, 0.525, 1.000))
	@extend .abs
	width 31.25%
	height 100%
	background white
	z-index: 5
	.about
		.block
			p
				display none
				&.active
					display block
		.logo
			img
				display none
				&:nth-child(2)
					margin-bottom 3px
				&.active
					display block
		.license-block
			display none
			&.active
				display inline-block
	// @media mw767
	// 	width 55%
	// 	left -55%
	// 	.about
	// 		display none
	&.open
		transform(translate3d(0,-92%,0))
		// @media mw767
		// 	transform(translate3d(100%,0,0))
		& .menu
			background #273136
			.inner-block .block:nth-child(3)
				opacity 1
			.open-button

				span
					color #fff
					opacity .3
					display block
					width 28vw
					background url(../images/close.png) 100% center no-repeat
					background-size 1.15em
					@media tablet
						margin-left 4vw
						width 26vw

				svg
					transform(rotate(90deg))
					@media tablet
						display none
					path
						stroke #61686c
				// @media mw767
				// 	span
				// 		background none
				// 	svg
				// 		display none
			ul
				li
					transform(translate3d(0,0,0))
					opacity 1
					span
						opacity 0.5
					&.active span
						opacity 1

	a.logo
		display inline-block
		width: 100%
		height 8.8vh
		img
			display inline-block
			margin-top 1vh
			height 7vh
		// @media mw767
		// 	margin-left 0
		// 	margin-bottom 20px
		// 	text-align center
	.inner-block
		@extend .box-sizing
		position relative
		width 100%
		padding 0 4vw
		@media tablet
			padding 0 3vw
		.block
			padding-bottom 25px
			@media mw1280
				padding-bottom 10px
			&:before
				padding-top 21px
				background url(../images/dot.png)
				background-repeat repeat-x
				content ''
				display block
				height 4px
				width 100%
				@media mw1280
					padding-top 20px
			p
				color #262f34
				font-size 1.375em * font-size
				font-family regular
				line-height 26px
				@media mw1280
					font-size 1.375em * font-size - 30%
					line-height 26px - 30%
			.map
				margin-bottom 20px
				height 180px
				@media mw1280
					height 180px - 30%
			span
				font-size 1.125em * font-size
				color #262f34
				line-height 2em
				display block
				&:nth-of-type(1)
					opacity .7
				@media mw1280
					font-size 1.125em * font-size - 30%
			a
				color #84d100
				font-size 1.125em * font-size
				@media mw1280
					font-size 1.125em * font-size - 30%
		ul
			li
				display inline-block
				list-style-type none
				margin-right 15px
				&:last-child
					margin-right 0
				img
					width: 4vw
					@media tablet
						width: 9vw
						margin-bottom 10px

	.menu
		transition(all .2s ease)
		transform(translate3d(0,92%,0))
		@extend .abs
		background white
		width 100%
		height 100%
		color white
		// @media mw767
		// 	transform(translate3d(0,0,0))
		// 	background #273136
		// 	ul
		// 		li
		// 			transform(translate3d(0,0,0))
		// 			opacity 1
		// 			span
		// 				opacity 0.5
		// 			&.active span
		// 				opacity 1
		.open-button
			width: 100%
			margin 3.5vh 0 3.5vh -33px

			span
				display inline-block
				color #EE3236
				cursor: pointer
				font-size 1.8em * font-size
				font-family bold
				@media mw1280
					font-size 1.5em * font-size - 30%
				// @media mw767
				// 	opacity 0
				// 	margin-left 4vw
			svg
				transition(all .2s ease .55s)
				cursor: pointer
				margin-right 10px
				float left
				width 20px
				height 22px
				@media mw1280
					width 20px - 30%
					height 22px - 30%
				// @media mw767
				// 	position absolute
				// 	top 0
				// 	right -7.5vw
				path
					stroke #EE3236
					// @media mw767
					// 	stroke #61686c

		ul
			margin-left -35px
			li
				transition(all .5s ease)
				transform(translate3d(0,50px,0))
				opacity 0
				text-transform uppercase
				color white
				margin 15px 0
				cursor: pointer
				font-size 1.5em * font-size
				line-height 1.3em
				display block
				width: 100%
				display none
				&.menu-btn
					display block
				// @media mw767
				// 	display block
				// 	transform(translate3d(-20px,0,0))
				span
					margin-left 34px
				&:nth-child(2)
					margin-top 0
					transition-delay .1s
					& span
						background url(../images/info.svg) left center no-repeat
						background-size 25px
						margin-left -3px
						padding-left 36px
						@media tablet
							background none
				&:nth-child(3)
					transition-delay .2s
					& span
						background url(../images/partners.svg) left center no-repeat
						background-size 28px
						margin-left -4px
						padding-left 37px
						@media tablet
							background none
				&:nth-child(4)
					transition-delay .3s
					& span
						background url(../images/objects.svg) left center no-repeat
						background-size 28px
						margin-left -3px
						padding-left 36px
						@media tablet
							background none
				&:nth-child(5)
					transition-delay .4s
					& span
						background url(../images/calendar.svg) left center no-repeat
						background-size 28px
						margin-bottom 0
						margin-left -3px
						padding-left 36px
						@media tablet
							background none
				&:nth-child(5)
					transition-delay .5s
				&:nth-child(6)
					transition-delay .6s
				&:nth-child(7)
					transition-delay .7s
				&:nth-child(1)
					transition-delay .0s


		.inner-block
			position relative
			height 100%
			.block
				span
					color white
				&:nth-child(3)
					transition(all .6s ease .5s)
					opacity 0
			.created
				position absolute
				height 20px
				width 100%
				bottom 5%
				left 4vw
				span
					font-size 1.125em * font-size
					@media mw1280
						font-size 1.125em * font-size - 30%
					a
						color #70838D