.pusher
	@extend .abs
	left 31.25%
	width 68.75%
	height 100%
	background white
	.about
		display none
		// @media mw767
		// 	@extend .left-block
		// 	display inline-block
		// 	width: 100%
		// 	height 50%
	transition(all .8s cubic-bezier(0.215, 0.870, 0.525, 1.000))
	&.open
		transform(translate3d(-100%,0,0))
		.down-content
			left: 100%;
		&.show-down
			transform(translate3d(-100%,-100%,0))
	&.show-down
		transform(translate3d(0,-100%,0))
		// @media mw767
		// 	.down-content
		// 		display block
	// @media mw767
	// 	left 0
	// 	position relative
	// 	display inline-block
	// 	height 80%
	// 	width 100%
	// 	&.open
	// 		transform(translate3d(0,0,0))
	// 		.down-content
	// 			left 0
	// 	&.show-down
	// 		transform(translate3d(0,0,0))
	// 	.about
	// 		@extend .left-block
	// 		display inline-block
	// 		position static
	// 		width: 100%
	// 		height 100%
	// 		.main-buttons
	// 			display inline-block
	.main-content
		height 100%;
		width: 100%

	.main-buttons
		width: 100%
		height 100%
	.open-button
		width 100%
		cursor pointer
		height 33.3333333%
		display table
		background-size cover
		position relative
		text-align: center
		text-decoration none
		overflow hidden
		&:after
			@extend .abs
			transition(all .4s ease-in-out)
			content ''
			width 100%
			height 100%
			top 0
			left 0
			background rgba(0, 0, 0, .4)
		&:hover:after
			background rgba(0, 0, 0, .2)

		&:nth-child(1)
			background url(../images/pic1.jpg) center 15% no-repeat
		&:nth-child(2)
			background url(../images/22.jpg) center 55% no-repeat
		&:nth-child(3)
			background url(../images/pic3.jpg) center no-repeat

		span
			transition(all .8s ease)
			transform(translate3d(0,0,0))
			opacity 1
			font-size 3.5em * font-size
			line-height 1.25em
			display: table-cell
			vertical-align: middle
			color white
			position relative
			z-index: 9
			@media mw1280
				font-size 3.5em * font-size - 30%

.right-content
	@extend .abs
	left: 100%
	width 100%
	height 100%
	// @media mw767
	// 	left: 0;
	// 	display none
	// 	&.active
	// 		display block
	.navigation
		@extend .abs
		z-index: 10
		width: 100%;
		height 9vh
		line-height 9vh
		&::after
			background url(../images/dot.png)
			background-repeat repeat-x
			content ''
			position absolute
			bottom 0
			left: 0
			display block
			height 4px
			width 100%
			opacity .4
		a
			color white
			font-family bold
			font-size 1.125em * font-size
			text-decoration none
			margin-right 4vw
			@media mw1280
				font-size 1.125em * font-size - 30%
		.back
			float left
			a
				// margin-left 1vw
				margin-right 0
				display inline-block
				padding-left 2vw
				// background url('')
				background url(../images/arrow-back.png) 1vw center no-repeat
				background-size 1.5vh
				@media tablet
					line-height 3vh
					width 10px
					color rgba(0,0,0,0)
		nav
			float right
			a
				@extend .box-sizing
				border-bottom 1px dashed white
				opacity 0.5
				&.active
					border-bottom none
					opacity 1
	section
		@extend .abs
		width 100%
		height 100%
		color white
		visibility hidden
		text-align left
		// overflow visible
		// display none
		&.active
			// display block
			visibility visible
			.abs
				h3
					transform(translate3d(0, 0, 0))
					opacity 1
				> div
					transform(translate3d(0, 0, 0))
					opacity 1
		.abs
			overflow-y scroll
			overflow-x hidden
			margin-top 9vh

			// height 83%
			height 83vh
			// @media tabletAlbum
			// 	height 80vh
			// @media only screen and (max-height : 600px)
			// 	height 78vh
			> div
				transition(all .6s ease)
				transform(translate3d(90px, 0, 0))
				@extend .box-sizing
				opacity 0
				padding 1em 2vw 0
				width 33.3333333%
				float left
				overflow-y visible
				&.hide
					opacity 0
				@media tablet
					padding 1em 1vw 0
				&:nth-child(1)
					transition-delay .05s
				&:nth-child(2)
					transition-delay .1s
				&:nth-child(3)
					transition-delay .15s
				h3

					position: relative
					font-size: 1.8em * font-size
					line-height 1.2em
					font-family bold
					margin-bottom 10px
					@media mw1280
						font-size: 1.8em * font-size - 30%
				ul
					li
						position: relative
						font-size 20px * font-size
						line-height 1.2em
						font-family medium
						list-style-position outside
						margin-left 15px
						margin-bottom 10px
						@media mw1280
							font-size 18px * font-size - 30%
						ul
							margin-top 10px

		.hide-blocks
			position absolute
			top: 93%;
			height 105%;
			left 0
			width 100%
			z-index 9
			.objects
				@extend .box-sizing
				transition(all .6s ease)
				background rgba(37, 47, 51, .0)
				float left
				height 107%
				width 33.333%
				padding 0 2vw 0
				position relative
				&.show
					background rgba(37, 47, 51, .7)
					transform(translate3d(0,-93%,0))
					h3
						opacity 1
					li
						opacity 1
				span
					text-align center
					display block
					height 7vh
					line-height 7vh
					text-transform: uppercase;
					font-family bold
					cursor pointer
					font-size 0.875em
					@media tablet
						font-size .875em * font-size - 30%
					i
						border-bottom 1px dashed white
						font-style normal
				.close-objects
					width 100%;
					height 8vh
					position absolute
					bottom 0
					left 0
					opacity .5
				h3
					transition(all .3s ease)
					opacity 0
					margin-top 6.5em
					position: relative
					font-size: 1.8em * font-size
					line-height 1.2em
					font-family bold
					margin-bottom 30px
					@media mw1280
						font-size: 1.8em * font-size - 30%
				li
					transition(all .3s ease)
					opacity 0
					cursor pointer
					display block
					width: 100%
					height 90px
					margin-bottom 20px

					h5
						font-size 1.125em * font-size
						@media mw1280
							font-size 1.125em * font-size - 20%
						color #fff
						margin-bottom 15px
						line-height 1.3em
					p
						font-size 1em * font-size
						@media mw1280
							font-size 1em * font-size - 20%
						color #adb9bf
					.image
						width: 70px
						height 70px
						overflow hidden
						position relative
						float left
						margin-right 20px
						img
							@extend .abs
							margin auto
							right 0
							bottom 0
							width auto
							height 70px

		article
			float left
			width 33.333333%
			height 100%
			background-size cover
			position relative
			&::before
				@extend .abs
				content ''
				width: 100%
				height 100%
				background rgba(37, 47, 51, .7)
		&.organization article:nth-child(1)
			background url(../images/org2.jpg) center no-repeat
		&.organization article:nth-child(2)
			background url(../images/22.jpg) center no-repeat
		&.organization article:nth-child(3)
			background url(../images/24.jpg) 70% center no-repeat
		&.service article:nth-child(1)
			background url(../images/service1.jpg) center no-repeat
		&.service article:nth-child(2)
			background url(../images/service2.jpg) center no-repeat
		&.service article:nth-child(3)
			background url(../images/service3.jpg) center no-repeat
		&.project article
			&::before
				background none
		.centered
			width 100%
			height 100%
			background #273136
			position relative
			span
				position absolute
				left 0
				top 0
				right 0
				bottom 0
				margin auto
				color #BEC1C2
				width 100%
				text-align center
				height 20px
