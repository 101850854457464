.partners
	height 100%
	width 100%
	.block-partners
		float left
		@extend .box-sizing
		padding 0 2vw
		&:nth-child(1)
			width: 66%
			position relative
			height 100%
			&::after
				background url(../images/dot-vert.png)
				background-repeat repeat-y
				background-size 100%
				content ''
				position absolute
				right: 0;
				top: 0;
				height 100%
				width 3px
		&:nth-child(2)
			width: 33%
			ul
				margin-top 20px
				li
					list-style-type none
					text-align center
					margin 0 0 20px
					position relative
					width 100%
					height 7vh
					display inline-block
					&:hover img
						-webkit-filter: grayscale(0%)

					img
						-webkit-filter: grayscale(100%)
						@extend .abs
						right 0
						bottom 0
						margin auto
						width 12vh
					&:nth-child(1) img
						width 20vh
						height auto
						@media tablet
							width 15vh
					&:nth-child(2) img
						width auto
						height 6vh
						@media tablet
							height 5vh
					&:nth-child(3) img
						width auto
						height 7vh
					&:nth-child(4) img
						width auto
						height 6vh
						@media tablet
							height 5vh
					&:nth-child(5) img
						width auto
						height 7vh
					&:nth-child(6) img
						width auto
						height 6vh
					&:nth-child(6) img
					&:nth-child(7) img
						width 8vh
						height auto
					&:nth-child(9) img
						width auto
						height 9vh
		h2
			height 3em
			line-height 1.3em
			margin-top 1.7em
			color #262f34
			font-size 1.625em * font-size
			@media mw1280
				font-size 1.625em * font-size - 20%

		h4
			color #262f34
			font-size 1.25em * font-size
			@media mw1280
				font-size 1.25em * font-size - 20%
		ul
			margin-bottom 40px
			li
				font-size 1.125em * font-size
				color #616d73
				margin 20px 0
				margin-left 20px
				list-style-type disc
				list-style-position outside
				font-family medium
				@media mw1280
					font-size 1.125em * font-size - 20%