.events
  height 100%
  #back-to-top
    width 30px
    height 30px
    background red
    position absolute
    bottom 40px
    left 10px
    cursor pointer
    background url(../images/backtotop.png) center no-repeat
    background-size cover
    display none
  h2
    height 3em
    line-height 1.3em
    margin-top 1.7em
    color #262f34
    font-size 1.625em * font-size
    @media mw1280
      font-size 1.625em * font-size - 20%

    padding-left 2vw

  .block-events
    width 100%
    height 100%
    overflow-y scroll

    @extend .box-sizing
    position relative
    padding 0 2vw
    width 100%
    float left
    padding-bottom 100px


    &:nth-child(1):after
      background url(../images/dot-vert.png)
      background-repeat repeat-y
      background-size 100%
      content ''
      position absolute
      right: 0;
      top: 0;
      height 100%
      width 3px
      @media tablet
        display none
    .event
      padding 25px
      border-bottom 1px solid #ddd
      min-height 10vh
      display inline-block
      width: 100%
      .image-event
        width 15%
        min-height 10vh
        float left
        overflow hidden
        position relative
        @media tablet
          min-height 10vh
        img
          position absolute
          margin auto
          top 0
          bottom 0
          left 0
          right 0
          max-height 100%
          max-width 80%
      .text-event
        float right
        width 83%
        .date
          color #b2b2b2
          font-size 1.125em * font-size
          @media mw1280
            font-size 1.125em * font-size - 20%

        .title
          display block
          margin 0 0 10px 0
          font-family bold
          color #262f34
          font-size 1.25em * font-size
          @media mw1280
            font-size 1.25em * font-size - 20%

        p
          color #616d73
          font-size 1.125em * font-size
          @media mw1280
            font-size 1.125em * font-size - 20%

          line-height 1.3em