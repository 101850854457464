@font-face
  font-family 'lato-regular'
  src url('../fonts/Lato-Regular.eot')
  src url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Regular.woff') format('woff'),
		 url('../fonts/Lato-Regular.ttf') format('truetype')
  font-style normal
  font-weight normal

@font-face
  font-family 'lato-bold'
  src url('../fonts/Lato-bold.eot')
  src url('../fonts/Lato-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-bold.woff') format('woff'),
		 url('../fonts/Lato-bold.ttf') format('truetype')
  font-style normal
  font-weight normal

@font-face
  font-family 'lato-medium'
  src url('../fonts/Lato-Medium.eot')
  src url('../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Medium.woff') format('woff'),
		 url('../fonts/Lato-Medium.ttf') format('truetype')
  font-style normal
  font-weight normal

@font-face
  font-family 'lato-light'
  src url('../fonts/Lato-Light.eot')
  src url('../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Light.woff') format('woff'),
		 url('../fonts/Lato-Light.ttf') format('truetype')
  font-style normal
  font-weight normal
