.objects-content
  margin-left 2vw
  @extend .abs
  width 100%
  height 100%

  h2
    height 3em
    line-height 1.3em
    margin-top 1.7em
    color #262f34
    font-size 1.625em * font-size
    @media mw1280
      font-size 1.625em * font-size - 20%
  .tabs-button
    width 33%
    height 700px
    float left
    margin-right 3%
    ul
      overflow-y scroll
      height 100%
    li
      cursor pointer
      display inline-block
      width: 100%
      min-height 90px
      opacity .5
      margin-bottom 10px
      &.active
        opacity 1
      &.year
        min-height 110px
        &::before
          height 20px
          display block
          border-bottom 1px solid rgba(0,0,0,.4)
          margin-bottom 10px
          margin-top 10px
          content attr(data-year)
          
      a
        text-decoration none
      h5
        font-size 1.125em * font-size
        @media mw1280
          font-size 1.125em * font-size - 20%
        color #262f34
        margin-bottom 5px
        line-height 1.3em
      p
        font-size 1em * font-size
        @media mw1280
          font-size 1em * font-size - 20%
        color #adb9bf
      .info
        float left
        width: 65%
        margin-left 5%
      .image
        width: 30%
        height 70px
        overflow hidden
        position relative
        float left
        &.max
          img
            max-width auto
            max-height 100%
        img
          @extend .abs
          margin auto
          right 0
          left 0
          bottom auto
          max-width 100%
          height auto
  .tabs-contents
    width 52%
    height 600px
    float left
    position relative
    .tab-block
      @extend .abs
      transition(all .4s ease)
      display none
      &.active
        display block
    .view
      width 100%
      height 60vh
      display inline-block
      background #232C30
      position relative
      overflow hidden
      margin-bottom 10px
      cursor pointer
      img
        @extend .abs
        right 0
        bottom 0
        margin auto
        width 100%
        height auto
    .thumbs-wrap
      position relative
      height 60px
    .arrows
      width: 100%
      overflow visible
      .arrow
        @extend .abs
        background url(../images/ic_arrow.png) center no-repeat
        cursor pointer
        margin auto
        bottom -10px
        left auto
        right -10%
        width: 10%
        height 100%
        z-index: 99
        &.left
          transform(rotate(180deg))
          right auto
          left -10%
    .thumbs
      height 40vh
      width: 100%
      overflow hidden
      cursor pointer
      position relative
      .carusel
        transition(all .3s ease)
        .thumb
          float left
          height 7vh
          width 65px
          overflow hidden
          position relative
          margin-right 12px
          img
            height 10vh
            @extend .abs
            right 0
            bottom 0
            margin auto
            width: auto
    .description
      li
        display none
        font-size 1em * font-size
        color #8b9499
        margin-top 20px
        @media mw1280
          font-size 1em * font-size - 20%
        &::before
          content 'Описание: '
          color #273136
          font-family bold
        &.active
          display block
