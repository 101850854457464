.company
	height 100%
	.block-vertical
		@extend .box-sizing
		padding 0 2vw
		position relative
		height 100%
		&:nth-child(2)
			&::before
			&::after
				background url(../images/dot-vert.png)
				background-repeat repeat-y
				background-size 100%
				content ''
				position absolute
				left: 0;
				top: 0;
				height 100%
				width 3px
			&::after
				right 0
				left auto
		h4
			height 4em
			line-height 1.2em
			margin-top 1.7em
			color #262f34
		float left
		width 33.333333%
		h4
			font-size 1.625em * font-size
			@media mw1280
				font-size 1.625em * font-size - 20%
		.box
			width 100%
			height 13vh
			margin-bottom 20px
			.image-box
				height 100%
				width 25%
				overflow hidden
				float left
				margin-right 20px
				img
					width 100%
					height auto
			.title-box
				display inline-block
				width 60%
				p
					font-family bold
					font-size 1.125em * font-size
					margin 10px 0 10px
					line-height 1.2em
					@media mw1280
						margin 5px 0 5px
						font-size 1.125em * font-size - 20%
						line-height 1.2em - 20%
				span
					color #adb9bf
					font-size 1em * font-size
					@media mw1280
						font-size 1em * font-size - 20%
			.links-box
				margin-top 20px
				a
					font-size 1em * font-size
					color #77bb00
				@media mw1280
					margin-top 10px
					a
						font-size 1em * font-size - 20%
		form
			width: 100%
			input
			textarea
				@extend .box-sizing
				width 100%
				height 50px
				background #F6F6F6
				border-radius 10px
				border 1px solid #DBDBDB
				padding-left 20px
				font-size 14px
				margin-top 20px
				font-family regular
				@media mw1280
					font-size 1em * font-size - 20%
				&:first-child
					margin-top 0
				&:focus
					outline none
			label
				font-size .85em
				&.error
					color #70838D
			textarea
				font-size 14px
				height 100px
				padding-top 15px
				resize none
			input[type="submit"]
				background #78C158
				float right
				width: 55%
				height 50px
				color white
				text-transform uppercase
				font-size 1em * font-size
				padding 2px 0 0 0
				font-family bold
				@media mw1280
					font-size 1em * font-size - 20%