.down-content
	@extend .abs
	top: 100%;
	height 100%
	width 100%
	z-index 99
	// @media mw767
	// 	left 0
	// 	top: 0
	// 	// display: none
	// 	&.active
	// 		display block
	section
		background: #fff
		@extend .abs
		transition(all .4s ease)
		visibility: hidden
		opacity 0
		&.active
			visibility: visible
			opacity 1
			// @media mw767
			// 	transform(translate3d(0,0,0))

	@import "./objects.styl"
	@import "./company.styl"
	@import "./partners.styl"
	@import "./events.styl"